<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="card-plain">
            <template slot="header">
              <div class="header">
                <div class="header__title">
                  <h4 class="card-title">Management Paket Program</h4>
                  <p class="card-category">Management Paket Program</p>
                </div>
                <div class="card-action">
                  <Button
                    type="danger"
                    text="Kembali"
                    @click="navigateToBack"
                  />
                  <Button text="Tambah Paket Program" @click="navigateToCreate" />
                </div>
              </div>
            </template>
            <div class="form-filter">
              <BaseInput
                width="50"
                v-model="search"
                placeholder="Cari Berdasarkan nama"
              />
              <Button type="info" text="Cari" @click="getPrograms" />
            </div>
            <div class="table-responsive">
              <l-table
                class="table-hover"
                :isShowActions="true"
                :columns="table.columns"
                :data="table.data"
                @click="{}"
              >
                <template v-slot:actions="slotProps">
                  <td>
                    <button 
                      class="table-item-action table-item-action__edit" 
                      @click="onPacketProgramAction(ENUM_ACTION.EDIT, slotProps.id)"
                    >
                      <i class="fa fa-pencil-square-o fa-md"></i>
                    </button> |
                    <button 
                      class="table-item-action table-item-action__delete" 
                      @click="onPacketProgramAction(ENUM_ACTION.DELETE, slotProps.id)"
                    >
                      <i class="fa fa-trash fa-md"></i>
                    </button>
                  </td>
                </template>
              </l-table>
            </div>
          </card>
        </div>
        <Loader v-show="isLoading" colorClass="l-gray" />
        <!-- open Modal -->
        <Modal
          :showModal="showModal"
          :useHeader="true"
          :isConfirmation="true"
          textHeader="Tambah Program Form"
          @cancel="() => {}"
          @oke="() => {}"
          textButton="Simpan"
          top="10"
        >
          <template>
            <div class="modal-product">
              <label for="nama">Nama</label>
              <BaseInput
                placeholder="Masukan nama"
                type="text"
                v-model="form.name"
                :errorMessage="error.name"
                required
              />
              <label for="email">Email</label>
              <BaseInput
                placeholder="Masukan email"
                type="email"
                v-model="form.email"
              />
              <label for="phone">Nomor Handphone</label>
              <BaseInput
                addonLeftIcon="+62"
                placeholder="Masukan nomor handphone"
                type="number"
                :value="form.phone"
                @input="(value) => {}"
                required
              />
              <label for="address">Alamat</label>
              <BaseInput
                placeholder="Masukan alamat"
                type="text"
                v-model="form.address"
                required
              />
            </div>
          </template>
        </Modal>
        
        <!-- MODAL DELETE PACKET -->
        <Modal
          :showModal="showModalDeletePacket"
          :isConfirmation="true"
          :useHeader="false"
          width="28"
          @cancel="onPacketModalActions(ENUM_ACTION.CANCEL)"
          @oke="onPacketModalActions(ENUM_ACTION.OKE)"
        >
          <div class="text-confirmation">
            <h4>{{ textConfirmation }}</h4>
          </div>
        </Modal>
      </div>
      <Pagination
        :page="pagination.page"
        @prev="paginationActions(ENUM_ACTION.PREV)"
        @next="paginationActions(ENUM_ACTION.NEXT)"
      />
    </div>
  </div>
</template>

<script>
import LTable from "src/components/Table.vue";
import Card from "src/components/Cards/Card.vue";
import Button from '@/components/Button/Button.vue';
import ManageProgramRepository from "./app/usecase";
import { MapProgram } from "./app/mappers";
import { ENUM_ACTION } from "@/app/Constants";

export default {
  components: {
    LTable,
    Card,
    Button,
  },
  data() {
    return {
      ENUM_ACTION,
      table: {
        columns: [
          { label: "Nama", value: "name" },
          { label: "Biaya", value: "monthlyPrice" },
          { label: "Dibuat", value: "createdAt" },
          { label: "Diubah", value: "updatedAt" },
        ],
      },
      pagination: {
        page: 1,
        perPage: 10,
        totalPage: 1,
      },
      isLoading: false,
      showModal: false,
      type: "text",
      form: {
        name: "",
        email: "",
        phone: "",
        address: "",
        password: "",
      },
      search: "",
      error: [],
      notifications: {
        topCenter: false,
      },
      errorMessage: {
        empty: "Data anda tidak boleh kosong",
        email: "Email anda tidak valid",
        name: "nama tidak boleh angka",
        password: "Password harus lebih dari 8 huruf",
        phone: "Nomor handphone setidak nya 9 angka sampai 15 angka",
        address: "Pastikan alamat diisi dengan benar",
      },
      message: {
        empty: "Data anda tidak boleh kosong",
        email: "Email anda tidak valid",
        name: "nama tidak boleh angka",
        password: "Password harus lebih dari 8 huruf",
        phone: "Nomor handphone setidak nya 9 angka sampai 15 angka",
        address: "Pastikan alamat diisi dengan benar",
        response: 'Gagal Memuat data'
      },
      showModalDeletePacket: false,
      selectedPacketId: null,
      textConfirmation: '',
    };
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign, color, item) {
      this.$notifications.notify({
        message: this.message[item],
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color,
      });
    },
    async getPrograms() {
      this.isLoading = true;
      const programId = this.$route.query.program_id;
      const programType = this.$route.query.program_type;
      
      const response = await ManageProgramRepository.getAll(
        this.pagination.page,
        this.pagination.perPage,
        this.search,
        programId,
        programType,
      );
    
      if (response && !response.error) {
        this.table = {
          ...this.table,
          data: response.data.data.map((item) => {
            return MapProgram(item);
          }),
        };
      } else {
        this.notifyVue("top", "right", "danger", "failed");
      }

      this.isLoading = false;
    },
    navigateToDetail(event) {
      this.$router.push(
        `${this.$route.path}/detail/${event.name}?id=${event.id}`
      );
    },
    navigateToCreate() {
      this.$router.push(`${this.$route.path}/create?program_id=${this.$route.query.program_id}&program_type=${this.$route.query.program_type}`);
    },
    navigateToBack() {
      this.$router.back();
    },
    paginationActions(type) {
      if (
        type === ENUM_ACTION.NEXT &&
        this.pagination.page !== this.pagination.totalPage
      ) {
        this.pagination.page++;
      }

      if (
          type === ENUM_ACTION.PREV &&
          this.pagination.page > 1
        ) {
        this.pagination.page--;
      }

      this.getPrograms();
    },
    onPacketProgramAction(type, packetId) {
      if (type === ENUM_ACTION.DELETE) {
        this.textConfirmation = 'Apakah anda yakin ingin menghapus data tersebut ?'
        this.showModalDeletePacket = true
        this.selectedPacketId = packetId
      }

      if (type === ENUM_ACTION.EDIT) {
        const path = `${this.$route.path}/edit?program_id=${this.$route.query.program_id}&program_type=${this.$route.query.program_type}&packet_id=${packetId}`;
        this.$router.push(path);
      }
    },
    onPacketModalActions(type) {
      if(type === ENUM_ACTION.CANCEL) {
        this.showModalDeletePacket = false
      }

      if(type === ENUM_ACTION.OKE) {
        this.deletePacketProgram(this.selectedPacketId)
      }
    },
    async deletePacketProgram(id) {
      this.isLoading = true
      const response = await ManageProgramRepository.delete(id)
      
      if(response && !response.error) {
        this.message.response = "Success menghapus data"
        this.notifyVue("top", "right", "success", "response")
        this.getPrograms();
      } else {
        this.message.response = "Gagal menghapus data"
        this.notifyVue("top", "right", "danger", "response")
      }
      
      this.showModalDeletePacket = false
      this.isLoading = false
    },
  },
  created() {
    this.getPrograms();
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
}

.form-filter {
  display: flex;

  > div:first-child {
    margin-right: 20px;
  }
}

.card-action {
  display: flex;
  gap: 20px;
}

.table-item-action {
  display: inline-block;
  width: 36px;
  height: 36px;
  color: white;
  font-weight: bold;
  line-height: 36px;
  border-radius: 6px;
  box-sizing: border-box;
  border: none;

  &__paket {
    background-color: #4f8904;
  }
  
  &__edit {
    background-color: #1365b3;
  }
  
  &__delete {
    background-color: #d11313;
  }
}

</style>
