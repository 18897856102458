<template>
  <div class="dropdown" >
    <div @click="toggleChevron()" :class="{'disabled' : disabled}">
      <input type="text" :placeholder="placeholder" :value="value || valueProps" disabled/>
      <i :class="`nc-icon nc-stre-${chevron}`"></i>
    </div>
    <div v-if="chevron === 'down' && options.length" class="dropdown__autocomplete">
      <div
        v-for="(option, index) in options"
        :key="String(option.value) + index"
        class="dropdown__autocomplete__item"
        @click="onClick(option)"
      >
        <span :class="option.label === (value || valueProps) ? 'active' : ''">{{ option.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    defaultValue: {
      type: String | Number,
      default: ''
    },
    options: {
      type: Array,
      default: () => [ /** example [{ label: option1, value: option1 }] */],
    },
    placeholder: {
      type: String,
      default: 'selected',
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: '',
      chevron: 'up'
    };
  },
  computed: {
    valueProps(){
      if(this.value === null) return null
      const val = this.options?.find((item) => item.value === this.$props.defaultValue)
      if(!val) return null
      return val?.label
    }
  },
  methods: {
    onClick(option) {
      console.log(this.value, option.value);
      
      // ADD CONDITION, To make sure existing logic still works
      if(this.value === option.value || this.$props.defaultValue === option.value) {
        this.value = null
        this.$emit('input', null)
        this.chevron = 'up'
        return;
      }
      if(this.disabled) return
      this.value = option.label;
      this.chevron = 'up'
      this.$emit('input', option.value)
    },
    toggleChevron() {
      if(this.disabled) return
      this.chevron = this.chevron === 'up' ? 'down' : 'up'
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/lbd/variables";

.dropdown {
  cursor: pointer;
  
  > div:first-child {
    border: 1px solid #E3E3E3;
    position: relative;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > i {
      margin: 0 12px;
    }

    > input {
      border: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;
    }
  }

  &__autocomplete {
    margin-top: 12px;
    background-color:  rgb(252, 252, 252);
    padding: 8px;
    border-radius: 8px;
    box-shadow: $box-shadow;
    position: absolute;
    width: 100%;

    &__item {
      padding: 8px;
      margin-top: 4px;

      &:hover {
        cursor: pointer;
        background-color: $light-gray;
      } 
    }
  }
}

  .disabled {
    background-color: #E9ECFE;
    cursor: not-allowed;
  }

  .active {
  color: $blue-color-top;
}
</style>
