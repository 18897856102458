<template>
  <div class="edit-sub-program">
    <Modal
      :showModal="showModal"
      :isConfirmation="true"
      :useHeader="false"
      width="28"
      @cancel="onModalActions(ENUM_ACTION.CANCEL)"
      @oke="onModalActions(ENUM_ACTION.OKE)"
    >
    <div class="text-confirmation">
      <h4>{{ textConfirmation }}</h4>
    </div>
    </Modal>
    <Loader colorClass="l-gray" v-if="isLoading" />
    <div class="edit-sub-program__form">
      <div class="edit-sub-program__form__title">
        <label>Nama Program<span>*</span></label>
        <BaseInput v-model="form.name" placeholder="Silahkan masukan nama"/>
      </div>
      <div class="edit-sub-program__form__title">
        <label>Biaya Pendaftaran<span>*</span></label>
        <BaseInput type="currency" v-model="form.initiatePrice" placeholder="Silahkan masukan biaya pendaftaran "/>
      </div>
      <div class="edit-sub-program__form__title">
        <label>Biaya Tahunan (Min. 50%)<span>*</span></label>
        <BaseInput type="currency" v-model="form.yearlyPrice" placeholder="Silahkan masukan biaya tahunan"/>
      </div>
      <div class="edit-sub-program__form__description">
        <label>Deskripsi <span>*</span></label>
        <VueEditor
          v-model="form.description"
          placeholder="Silahkan masukan deskripsi program"
          @input="{}"
        />
      </div>
      <div class="edit-sub-program__form__title">
        <label>Gambar Program <span>*</span></label>
        <MultipleImage @value="onMultipleUpload" @close="onChangeUpload" :maxImages="1" :imageSrc="form.attachmentIds.length > 0 ? form.attachment?.map((img) => img?.url) : []" />
      </div>
      <div class="edit-sub-program__form__actions">
        <Button type="danger" text="Kembali" @click="onActions(ENUM_ACTION.BACK)" />
        <Button :isDisabled="$v.form.$invalid" type="info" text="Simpan" @click="onActions(ENUM_ACTION.SUBMIT)" />
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { ENUM_ACTION } from '@/app/Constants'
import { required, minLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import MultipleImage from '@/components/MultipleImage/MultipleImage.vue'
import ManageSubProgramUseCase from '../app/usecase'

export default {
  components: {
    MultipleImage
  },
  mixins: [validationMixin],
  data() {
    return {
      ENUM_ACTION,
      showModal: false,
      isSubmit: false,
      textConfirmation: '',
      isLoading: false,
      message: {
        success: 'program berhasil diubah',
        failed: 'program gagal diubah'
      },
      form: {
        name: '',
        description: '',
        attachment: [],
        attachmentIds: [],
        initiatePrice: ''
      }
    }
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign, color, typeMessage) {
      this.$notifications.notify({
        message: this.message[typeMessage],
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color,
      });
    },
    onModalActions(type) {
      if(type === ENUM_ACTION.CANCEL) {
        this.showModal = false
      }

      if(type === ENUM_ACTION.OKE) {
        this.editSubProgram(this.form, this.$route.params.subProgramId)
      }
    },
    onChange() {},
    onActions(type) {
      if(type === ENUM_ACTION.BACK) {
        this.$router.go(-1)
      }

      if(type === ENUM_ACTION.SUBMIT) {
        this.textConfirmation = 'Apakah data sudah benar ?'
        this.showModal = true
      }

    },
    onMultipleUpload(value) {
      const findImgNull = this.form.attachment?.find((att) => att.url === null)
      const findAttachment = this.form.attachment.findIndex((img) => img.id === findImgNull?.id)
      this.form.attachment[findAttachment] = {
        ...this.form.attachment[findAttachment],
        url: value[0][value[0].length -1],
        file: value[1][value[1].length -1]
      }
      
      this.form.attachmentIds = [...this.form.attachmentIds, findImgNull?.id]

    },
    onChangeUpload(value) {
    if(value.length > 0) {
      this.form.attachmentIds = this.form.attachmentIds.filter((i) => i !== this.form.attachmentIds[value[2]])
      this.form.attachment[value[2]] = {
        ...this.form.attachment[value[2]],
        url: null,
        file: null  
      }
    } else {
      this.form.attachmentIds = []
        this.form.attachment[0] = {
          ...this.form.attachment[0],
          url: null,
          file: null  
        } 
      }
    },
    onUploadPdf(value) {
      this.form.file = value[1]
    },
    async getSubProgram() {
      this.isLoading = true;
      const response = await ManageSubProgramUseCase.get(this.$route.params.subProgramId);
      if (response && !response.error) {
        const {
          images,
          description,
          name,
          yearlyPrice,
          initiatePrice,
        } = response.data
        
        this.form = {
          attachment: images?.map((img) => ({id: img?.id, url: img.url, file: null})),
          attachmentIds: images.map((img) => img.id),
          description,
          name,
          initiatePrice: initiatePrice?.replace('Rp', '')?.replace(',-', ''),
          yearlyPrice: yearlyPrice?.replace('Rp', '')?.replace(',-', '')
        }
      } else {
        this.notifyVue("top", "right", "danger", "failed");
      }
      
      this.isLoading = false;
    },
    async editSubProgram(data, id) {
      this.isLoading = true
      const payload = new FormData()
      if(data.attachment && data.attachment.length){
        data.attachment.forEach((item, index) => {
          payload.append(`images_${item?.id}`, item?.file)
        });
      }
      payload.append('name', data.name)
      payload.append('initiate_price', data.initiatePrice?.replaceAll('.', ''))
      payload.append('yearly_price', data.yearlyPrice?.replaceAll('.', ''))
      payload.append('description', data.description)

      const response = await ManageSubProgramUseCase.edit(payload, id)
      if(response && !response.error) {
        this.notifyVue('top', 'right', 'success', 'success')
        this.$router.go(-1)
      } else {
        this.notifyVue('top', 'right', 'danger', 'failed')
      }

      this.showModal = false
      this.isLoading = false
    },
  formattedNameFile(string) {
    return string.replaceAll('/', ' ').split(' ').pop()
  },
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        description: {
          required,
        },
        attachment: {
          required,
          minLength: minLength(1)
        },
        attachmentIds: {
          required,
          minLength: minLength(this.form.attachment.length)
        }
      }
    }
  },
  created() {
    this.getSubProgram()
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/sass/lbd/variables';

.edit-sub-program {
  width: 90%;
  background-color: white;
  margin: auto;
  padding: 20px;

  &__form {
    display: flex;
    flex-direction: column;

    > div:first-child {
      margin-bottom: 12px;
    }

    > div {
      margin-bottom: 32px;

      > label {
        color: $text-base;
        font-weight: 600;

        span {
          color: $danger-states-color;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;

      > div {
        margin-right: 12px;
      }
    }
  }
}

.text-confirmation {
  text-align: center;
  margin-bottom: 20px;
}

textarea {
  padding: 10px 20px;
  width: 100%;
  color: $text-base;
  border: 1px solid $light-gray;
  border-radius: 4px;

  &::placeholder {
    color: $light-gray;
  }
}

textarea:focus {
  outline: none;
  border: 1px solid $dark-gray;
}
</style>
