<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card class="card-plain">
          <template slot="header">
            <div class="header">
              <div class="header__title">
                <h4 class="card-title">Management Akun Bank</h4>
                <p class="card-category">Management Akun Bank</p>
              </div>
              <Button text="Tambah Akun Bank" @click="addUser" />
            </div>
          </template>
          <div class="form-filter">
            <BaseInput width="50" v-model="search" placeholder="Cari Berdasarkan nama bank" />
            <Button type="info" text="Cari" @click="onSearch" />
          </div>
          <div class="table-responsive">
            <l-table
              class="table-hover"
              :isShowActions="true"
              :columns="table.columns"
              :data="table.data"
              @click="{}"
            >
              <template v-slot:actions="slotProps">
                <td>
                  <button 
                    class="table-item-action table-item-action__edit" 
                    @click="onAccountBankItemClick(ENUM_ACTION.EDIT, slotProps.id)"
                  >
                    <i class="fa fa-pencil-square-o fa-md"></i>
                  </button> |
                  <button 
                    class="table-item-action table-item-action__delete" 
                    @click="onAccountBankItemClick(ENUM_ACTION.DELETE, slotProps.id)"
                  >
                    <i class="fa fa-trash fa-md"></i>
                  </button>
                </td>
              </template>
            </l-table>
          </div>
        </card>
      </div>
      <Loader v-show="isLoading" colorClass="l-gray" />
      
      <!-- MODAL DELETE -->
      <Modal
        :showModal="showModalDelete"
        :isConfirmation="true"
        :useHeader="false"
        width="28"
        @cancel="onModalDeleteAction(ENUM_ACTION.CANCEL)"
        @oke="onModalDeleteAction(ENUM_ACTION.OKE)"
      >
        <div class="text-confirmation">
          <h4>{{ textConfirmation }}</h4>
        </div>
      </Modal>
      
      <!-- open Modal -->
      <Modal
        :showModal="showModal"
        :useHeader="true"
        :isConfirmation="true"
        :textHeader="formHeaderTitle"
        @cancel="onActionsModal('cancel')"
        @oke="onActionsModal('oke')"
        :textButton="formSubmitBtnTitle"
        top="10"
      >
        <template>
          <div class="modal-user">
            <label for="nama">Nama Akun Bank</label>
            <BaseInput
              placeholder="Masukan nama akun bank"
              type="text"
              v-model="form.bankAccountName"
              :errorMessage="error.bankAccountName"
              required
            />
            <label for="email">Nomor Akun Bank</label>
            <BaseInput
              placeholder="Masukan nomor akun bank"
              type="text"
              v-model="form.bankAccountNumber"
              :errorMessage="error.bankAccountNumber"
              required
            />
            <label for="email">Nama Penerima</label>
            <BaseInput
              placeholder="Masukan nama penerima"
              type="text"
              v-model="form.bankAccountRecipient"
              :errorMessage="error.bankAccountRecipient"
              required
            />
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import LTable from "src/components/Table.vue";
import Card from "src/components/Cards/Card.vue";
import Button from "@/components/Button/Button.vue";
import ManageUserUseCase from './app/usecase'
import { MapUser, MapPayloadUser } from './app/mappers'
import { TimeMapper } from '@/app/utils'
import BaseInput from "@/components/Inputs/BaseInput.vue";
import Modal from '@/components/Modal/Modal.vue'
import Loader from '@/components/Loader/Loader.vue'
import { ENUM_ACTION } from "@/app/Constants";

export default {
  components: {
    LTable,
    Card,
    Button,
    BaseInput,
    Modal,
    Loader,
  },
  data() {
    return {
      ENUM_ACTION,
      table: {
        columns: [
          { label: 'Nama Bank', value: 'bankAccountName' },
          { label: 'Nomor Akun Bank', value: 'bankAccountNumber' },
          { label: 'Nama Penerima', value: 'bankAccountRecipient' },
          { label: 'Dibuat', value: 'createdAt' }
        ],
        data: []
      },
      form: {
        bankAccountName: "",
        bankAccountNumber: "",
        bankAccountRecipient: "",
      },
      isLoading: false,
      showModal: false,
      type: 'text',
      errorMessage: {
        empty: "Data anda tidak boleh kosong",
        bankAccountName: "nama tidak boleh angka",
        bankAccountNumber: "Password harus lebih dari 8 huruf",
        bankAccountRecipient: "Email anda tidak valid",
        response: 'Gagal membuat data user'
      },
      error: [],
      search: '',
      showModalDelete: false,
      selectedData: null,
      textConfirmation: '',
      formHeaderTitle: '',
      formSubmitBtnTitle: '',
      typeForm: 'CREATE',
    };
  },
  computed: {
    typePassword() {
      return this.type;
    },
  },
  methods: {
    handleClick(value) {
      this.$router.push(`${this.$route.path}/${value.name}?id=${value.id}`);
    },
    addUser() {
      this.showModal = true;
      this.formHeaderTitle = 'Tambah Akun Bank Form';
      this.formSubmitBtnTitle = 'Simpan';
      this.typeForm = 'CREATE';
      this.form = {
        bankAccountName: '',
        bankAccountNumber: '',
        bankAccountRecipient: '',
      };
    },
    notifyVue(verticalAlign, horizontalAlign, color, item) {
      this.$notifications.notify({
        message: this.errorMessage[item],
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color,
      });
    },
    async getAllUsers() {
      this.isLoading = true
      const response = await ManageUserUseCase.getAll(this.search)
      
      if (response && !response.error) {
        this.table = {
          ...this.table,
          data: response.data.data.map((value) => {
            const user = MapUser(value);
            return {
              ...user,
              createdAt: TimeMapper(user.createdAt),
            };
          }),
        }
      } else {
        this.$notifications.notify({
          message: 'Gagal memuat data',
          icon: "nc-icon nc-app",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'danger',
        })
      }
      this.isLoading = false
    },
    async createUser(payload) {
      this.isLoading = true;
      if (this.validation()) {
        const response = await ManageUserUseCase.create(
          MapPayloadUser(payload)
        );
        if (response && !response.error) {
          this.errorMessage = {
            ...this.errorMessage,
            create: "Akun user berhasil di tambahkan",
          };
          this.notifyVue("top", "right", "success", "create");
          this.getAllUsers();
          this.showModal = false;
        } else {
          if (!Boolean(this.error.indexOf("create") + 1)) {
            this.error.push("create");
          }

          if (response.error.message.includes("Email")) {
            this.errorMessage = {
              ...this.errorMessage,
              create: "Email anda sudah terpakai",
            };
          }

          if (response.error.message.includes("Phonenumber")) {
            this.errorMessage = {
              ...this.errorMessage,
              create: "Nomor Handphone sudah terpakai",
            };
          }

          if(typeof response.error ===  'string') {
            this.notifyVue("top", "right", "danger", response);
          }

          this.error.map((item) => {
            this.notifyVue("top", "right", "danger", item);
          });
        }
      } else {
        this.error.map((item) => {
          this.notifyVue("top", "right", "danger", item);
        });
      }

      this.isLoading = false;
    },
    onActionsModal(type) {
      if (type === "cancel") {
        this.showModal = false;
      }

      if (type === "oke") {
        if (this.typeForm === 'CREATE') {
          this.createUser(this.form);
        } else {
          this.editAccountBank(this.form, this.selectedData);
        }
      }
    },
    onInputPhone(value) {
      const el = document.querySelector('input[type="number"]');
      if (this.form.phone.length < 1 && value !== "8") {
        el.value = "";
        return;
      } else {
        if (value.length > 15) {
          el.value = this.form.phone;
          return;
        } else {
          return (this.form.phone = value);
        }
      }
    },
    onFocus(type) {
      if (type === "blur" && !this.form.password) {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
    togglePassword() {
      if (this.type === "password") this.type = "text";
      else this.type = "password";
    },
    validation() {
      this.error = [];
      let result = false;
      
      const everyFill = !!(
        this.form.bankAccountName &&
        this.form.bankAccountNumber &&
        this.form.bankAccountRecipient
      );

      if (!everyFill) {
        if (!Boolean(this.error.indexOf("empty") + 1)) {
          this.error.push("empty");
        }
      }
      
      if (this.error.length === 0) {
        result = true
      }
      
      return result;
    },
    onClick(type) {
      if (type === "prev" && this.pagination.page !== 1) {
        this.pagination.page--;
      }

      if (type === "next" && this.pagination.page !== this.pagination.totalPage) {
        this.pagination.page++;
      }

      this.getAllUsers()
    },
    onSearch() {
      this.pagination.page = 1
      this.getAllUsers()
    },
    onAccountBankItemClick(type, id) {
      if (type === ENUM_ACTION.DELETE) {
        this.textConfirmation = 'Apakah anda yakin ingin menghapus data tersebut ?'
        this.showModalDelete = true
        this.selectedData = id
      }

      if (type === ENUM_ACTION.EDIT) {
        const selectedData = this.table.data.filter((item) => item.id === id)[0];
        this.formHeaderTitle = 'Edit Akun Bank Form';
        this.formSubmitBtnTitle = 'Edit';
        this.typeForm = 'EDIT';
        this.showModal = true;
        this.selectedData = id
        this.form = {
          bankAccountName: selectedData.bankAccountName,
          bankAccountNumber: selectedData.bankAccountNumber,
          bankAccountRecipient: selectedData.bankAccountRecipient,
        };
      }
    },
    onModalDeleteAction(type) {
      if(type === ENUM_ACTION.CANCEL) {
        this.showModalDelete = false
      }

      if(type === ENUM_ACTION.OKE) {
        this.deleteAccountBank(this.selectedData)
      }
    },
    async deleteAccountBank(id) {
      this.isLoading = true
      const response = await ManageUserUseCase.delete(id)
      
      if(response && !response.error) {
        this.errorMessage.response = "Success menghapus data"
        this.notifyVue("top", "right", "success", "response")
        this.getAllUsers()
      } else {
        this.errorMessage.response = "Gagal menghapus data"
        this.notifyVue("top", "right", "danger", "response")
      }
      
      this.showModalDelete = false
      this.isLoading = false
    },
    async editAccountBank(data, id) {
      this.isLoading = true;
      const payload = MapPayloadUser(data);
      const response = await ManageUserUseCase.update(id, payload)
      if(response && !response.error) {
        this.getAllUsers();
        this.notifyVue('top', 'right', 'success', 'success')
      } else {
        this.notifyVue('top', 'right', 'danger', 'failed')
      }

      this.showModal = false
      this.isLoading = false
    },
  },
  created() {
    this.getAllUsers()
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
}

.form-filter {
  display: flex;

  > div:first-child {
    margin-right: 20px;
  }
}

.table-item-action {
  display: inline-block;
  width: 36px;
  height: 36px;
  color: white;
  font-weight: bold;
  line-height: 36px;
  border-radius: 6px;
  box-sizing: border-box;
  border: none;

  &__paket {
    background-color: #4f8904;
  }
  
  &__edit {
    background-color: #1365b3;
  }
  
  &__delete {
    background-color: #d11313;
  }
}

</style>
