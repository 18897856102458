<template>
  <div class="create-program">
    <Modal
      :showModal="showModal"
      :isConfirmation="true"
      :useHeader="false"
      width="28"
      @cancel="onModalActions(ENUM_ACTION.CANCEL)"
      @oke="onModalActions(ENUM_ACTION.OKE)"
    >
    <div class="text-confirmation">
      <h4>{{ textConfirmation }}</h4>
    </div>
    </Modal>
    <Loader colorClass="l-gray" v-if="isLoading" />
    <div class="create-program__form">
      <div class="create-program__form__title">
        <label>Nama Sub Program<span>*</span></label>
        <BaseInput v-model="form.name" placeholder="Silahkan masukan nama"/>
      </div>
      <div class="create-program__form__title">
        <label>Biaya Pendaftaran <span>*</span></label>
        <BaseInput type="currency" v-model="form.initiate_price" placeholder="Silahkan masukan biaya pendaftaran"/>
      </div>
      <div class="create-program__form__title">
        <label>Biaya Tahunan (Min. 50℅) <span>*</span></label>
        <BaseInput type="currency" v-model="form.yearly_price" placeholder="Silahkan masukan biaya tahunan"/>
      </div>
      <div class="create-program__form__description">
        <label>Deskripsi <span>*</span></label>
        <VueEditor
          v-model="form.description"
          placeholder="Silahkan masukan deskripsi program"
          @input="{}"
        />
      </div>
      <div class="create-program__form__title">
        <label>Gambar Sub Program <span>*</span></label>
        <MultipleImage @value="onMultipleUpload" :maxImages="1"/>
      </div>
      <div class="create-program__form__actions">
        <Button type="danger" text="Kembali" @click="onActions(ENUM_ACTION.BACK)" />
        <Button :isDisabled="$v.form.$invalid" type="info" text="Simpan" @click="onActions(ENUM_ACTION.SUBMIT)" />
      </div>
    </div>
  </div>
</template>

<script>
import { ENUM_ACTION } from '@/app/Constants'
import { required, minLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import MultipleImage from '@/components/MultipleImage/MultipleImage.vue'
import ManageProgramUseCase from '../app/usecase'

export default {
  components: {
    MultipleImage
  },
  mixins: [validationMixin],
  data() {
    return {
      ENUM_ACTION,
      showModal: false,
      isSubmit: false,
      textConfirmation: '',
      isLoading: false,
      message: {
        success: 'program berhasil di buat',
        failed: 'program gagal di buat'
      },
      form: {
        name: '',
        description: '',
        attachment: [],
        file: null,
        price: '',
      }
    }
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign, color, typeMessage) {
      this.$notifications.notify({
        message: this.message[typeMessage],
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color,
      });
    },
    onModalActions(type) {
      if(type === ENUM_ACTION.CANCEL) {
        this.showModal = false
      }

      if(type === ENUM_ACTION.OKE) {
        this.createProgram(this.form)
      }
    },
    onChange() {},
    onActions(type) {
      if(type === ENUM_ACTION.BACK) {
        this.$router.back();
      }

      if(type === ENUM_ACTION.SUBMIT) {
        this.textConfirmation = 'Apakah data sudah benar ?'
        this.showModal = true
      }

    },
    onMultipleUpload(value) {
      
      this.form.attachment = value[1]
    },
    async createProgram(data) {
      this.isLoading = true
      const payload = new FormData()
      data.attachment.forEach(item => {
        payload.append('images[]', item)
      });
      
      payload.append('program_id', this.$route.query.id);
      payload.append('name', data.name)
      payload.append('initiate_price', data.initiate_price?.replaceAll('.', ''))
      payload.append('yearly_price', data.yearly_price?.replaceAll('.', ''))
      payload.append('description', data.description)

      const response = await ManageProgramUseCase.create(payload)
      if(response?.data && !response.error) {
        this.$router.go(-1);
        this.notifyVue('top', 'right', 'success', 'success')
      } else {
        this.notifyVue('top', 'right', 'danger', 'failed')
      }

      this.showModal = false
      this.isLoading = false
    }
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        description: {
          required,
        },
        attachment: {
          required,
          minLength: minLength(1)
        },
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/sass/lbd/variables';

.create-program {
  width: 90%;
  background-color: white;
  margin: auto;
  padding: 20px;

  &__form {
    display: flex;
    flex-direction: column;

    > div:first-child {
      margin-bottom: 12px;
    }

    > div {
      margin-bottom: 32px;

      > label {
        color: $text-base;
        font-weight: 600;

        span {
          color: $danger-states-color;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;

      > div {
        margin-right: 12px;
      }
    }
  }
}

.text-confirmation {
  text-align: center;
  margin-bottom: 20px;
}

</style>
