<template>
  <div>
    <table>
      <thead>
        <slot name="columns">
          <tr>
            <th
            v-for="(column, index) in columns"
            :key="index"
            style="text-align: start;"
            :style="column.style"
            >
              <button
                class="button-sort"
                v-if="showFilterButton(column.value)"
                @click="handleSort()"
              >
                <i :class="`nc-icon nc-stre-${chevron}`"></i>
              </button>
              {{ column.label }}
            </th>
            <th v-if="isShowActions">
              Actions
            </th>
          </tr>
        </slot>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in data"
          :key="index"
          @click="$emit('click', item)"
        >
          <slot :row="item">
            <td
              style="text-align: start"
              v-for="(column, index) in columns"
              :key="index"
            >
              <span v-if="column !== 'Actions'">
                <span
                  v-if="showCircle(column.value)"
                  class="status-circle"
                  :style="{ backgroundColor: getStatusCircleColor(itemValue(item, 'transactionStatus')) }"
                ></span>
                {{ itemValue(item, column.value) }}
              </span>
              <div v-else class="actions-group">
                <Button
                  v-for="action of item.actions"
                  :key="action.id"
                  :text="action.text"
                  :type="action.type"
                  :customPadding="
                    Boolean(action.customPaddin) ? action.customPaddin : false
                  "
                  @click="action.handler"
                />
              </div>
            </td>
            <slot name="actions" :id="item.id"></slot>
          </slot>
        </tr>
      </tbody>
      <slot name="tfoot"></slot>
    </table>
    <div v-if="!Boolean(data && data.length)" class="not-found">
      <h4>Data tidak ada</h4>
    </div>
  </div>
</template>
<script>
import Button from "./Button/Button.vue";

export default {
  name: "l-table",
  props: {
    isShowActions: Boolean,
    columns: Array,
    data: Array,
    sortFunction: {
      type: Function,
      required: false,
    },
  },
  components: { Button },
  data() {
    return {
      chevron: 'up',
    };
  },
  methods: {
    itemValue(item, column) {
      if(column === 'isFavorite') {
        return item[column] === true ? 'YES' : 'NO'
      }

      if(column === 'favoriteOrderNumber') {
        return item[column] === 0 ? "tidak ada" : item[column]
      }
      
      return item[column];
    },
    showCircle(column) {
      return column === 'transactionStatus';
    },
    showFilterButton(column) {
      return column === 'dueDate';
    },
    getStatusCircleColor(status) {
      const statusColors = {
        'Waiting Verification': '#FFFF99',
        'Processed': '#B2FF66',
        'Rejected': '#FF6666',
        'Cancel': '#CCCCCC',
        'Done': '#66B2FF',
      };

      return statusColors[status] || '#CCCCCC';
    },
    handleSort() {
      this.chevron = this.chevron === 'up' ? 'down' : 'up';
      this.sortFunction(this.chevron);
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  width: 100%;
  text-align: center;

  > thead {
    > tr {
      > th:first-child {
        border-radius: 8px 0 0 8px;
      }

      > th:last-child {
        border-radius: 0 8px 8px 0;
      }

      > th {
        padding: 12px;
        background-color: #52616b;
        color: #f0f5f9;
      }
    }
  }

  > tbody {
    > tr {
      > td {
        color: #3e4149;
        padding: 20px;
        border-bottom: 1px solid #9999;
        cursor: pointer;

        > .actions-group {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  
  > tfoot {
    > tr {
      > td {
        color: #3e4149;
        padding: 20px;
        border-bottom: 1px solid #9999;
        cursor: pointer;
        text-align: left;
        font-weight: bold;
      }
    }
  }
}

.not-found {
  text-align: center;
  margin: 32px 0;
  color: #3e4149;
}

.status-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.button-sort {
  border: 0;
  background-color: transparent;
  color: #fff;
}
</style>
