<template>
  <div v-if="showModal" class="modal">
    <div :style="`margin: ${top}% ${width}%;`" class="modal__component">
      <div v-if="useHeader" class="modal__component__header">
        <h3>{{ textHeader }}</h3>
      </div>
      <slot></slot>
      <div v-if="useFooter" class="modal__component__footer">
        <Button v-if="isConfirmation" :text="textButton1" type="danger" :customPadding="false" @click="$emit('cancel')"/>
        <Button :text="textButton" type="success" :customPadding="false" @click="$emit('oke')"/>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../Button/Button.vue";

export default {
  name: "Modal",
  components: { Button },
  props: {
    useHeader: {
      type: Boolean,
      default: true
    },
    textHeader: {
      type: String,
      default: 'this is header'
    },
    useFooter: {
      type: Boolean,
      default: true
    },
    isConfirmation: {
      type: Boolean,
      default: false
    },
    textButton1: {
      type: String,
      default: 'cancel'
    },
    textButton: {
      type: String,
      default: 'Oke'
    },
    width: {
      type: String,
      default: '20' // is 100%
    },
    top: {
      type: String,
      default: '20' // is 100%
    },
    showModal: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style lang="scss" scoped>

.modal {
  background-color: rgba(0, 0, 0, 0.06);
  position: fixed;
  display: block;
  width: 100%;
  right: 0;
  height: 100%;
  min-height: 100%;
  overflow: auto;

  &__component {
    border-radius: 8px;
    background-color: white;
    border: 0.5px solid #3333;
    box-sizing: border-box;
    padding: 12px 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    &__header {
      padding: 0px 16px;
      text-align: center;
      border-bottom: 1px solid #4444;
      margin-bottom: 20px;
    }

    &__footer {
      margin-top: 40px;
      display: flex;
      justify-content: space-around;
    }
  }
}

/deep/.custom-padding {
  padding: 8px 120px;
}
</style>
