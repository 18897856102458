<template>
  <div class="product">
    <Loader v-show="isLoading" colorClass="l-gray" />
    <BackComponent />
    <div v-if="data" class="product__detail">
      <div class="product__detail__image">
        <img :src="data?.attachment?.[0]?.url || ''" alt="" />
        <Button v-if="data.type === 'digital'" type="success" text="Download" @click="handleDownload()" />
      </div>
      <div class="product__detail__desc">
        <label>Nama:</label>
        <p>{{ data.name }}</p>
        <label>Tipe:</label>
        <p>{{ data.type }}</p>
        <label>Harga:</label>
        <p>{{ data.price }}</p>
        <label>Harga Final:</label>
        <p>{{ data.finalPrice }}</p>
        <label>Persentase Diskon:</label>
        <p>{{ data.discountPercentage }}%</p>
        <label>Dibuat:</label>
        <p>{{ data.createdAt }}</p>
        <label>Diubah:</label>
        <p>{{ data.updatedAt }}</p>
      </div>
    </div>
    <label style="font-weight: 600; color: #4d4d4d;">Deskripsi:</label>
      <p v-html="data?.description || ''" />
    <Modal
      :showModal="showModalDelete"
      :isConfirmation="true"
      :useHeader="false"
      width="28"
      @cancel="onModalActions(ENUM_ACTION.CANCEL)"
      @oke="onModalActions(ENUM_ACTION.OKE)"
    >
    <div class="text-confirmation">
      <h4>{{ textConfirmation }}</h4>
    </div>
    </Modal>
    <div class="product__detail__actions">
      <Button text="Edit" @click="onActions(ENUM_ACTION.EDIT)"/>
      <Button text="Hapus" type="danger" @click="onActions(ENUM_ACTION.DELETE)"/>
    </div>
  </div>
</template>

<script>
import ManageProductUseCase from "../app/usecase";
import Loader from "@/components/Loader/Loader.vue";
import { ENUM_ACTION } from '@/app/Constants'

export default {
  components: { Loader },
  data() {
    return {
      ENUM_ACTION,
      data: null,
      isLoading: false,
      showModalDelete: false,
      textConfirmation: '',
      errorMessage: {
        empty: "Data anda tidak boleh kosong",
        email: "Email anda tidak valid",
        name: "nama tidak boleh angka",
        password: "Password harus lebih dari 8 huruf",
        phone: "Nomor handphone setidak nya 9 angka sampai 15 angka",
        address: "Pastikan alamat diisi dengan benar",
        response: 'Gagal Memuat data'
      },
    };
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign, color, item) {
      this.$notifications.notify({
        message: this.errorMessage[item],
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color,
      });
    },
    async getProduct() {
      this.isLoading = true;
      const response = await ManageProductUseCase.get(this.$route.query.id);
      if (response && !response.error) {
        this.data = response.data;
      } else {
        this.notifyVue("top", "right", "danger", "failed");
      }

      this.isLoading = false;
    },
    async handleDownload() {
        const url = this.data.files[0].url;
        const el = document.createElement('a')
        el.target = '_blank'
        el.href = url
        el.click()
    },
    onActions(type) {
      if(type === ENUM_ACTION.DELETE) {
        this.textConfirmation = 'Apakah anda yakin ingin menghapus data tersebut ?'
        this.showModalDelete = true
      }

      if(type === ENUM_ACTION.EDIT) {
        this.$router.replace(`/management-product/edit/${this.$route.params.id}?id=${this.$route.query.id}`)
      }
    },
    onModalActions(type) {
      if(type === ENUM_ACTION.CANCEL) {
        this.showModalDelete = false
      }

      if(type === ENUM_ACTION.OKE) {
        this.deleteProduct()
      }
    },
    async deleteProduct() {
      this.isLoading = true
      const response = await ManageProductUseCase.delete(this.$route.query.id)
      if(response && !response.error) {
        this.errorMessage.response = "Success menghapus data"
        this.notifyVue("top", "right", "success", "response")
        this.$router.push('/management-product')
      } else {
        this.errorMessage.response = "Gagal menghapus data"
        this.notifyVue("top", "right", "danger", "response")
      }

      this.isLoading = false
    }
  },
  created() {
    this.getProduct();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/sass/lbd/variables";
.product {
  background-color: white;
  width: 90%;
  margin: 20px auto;
  margin-bottom: 20px;
  border-radius: 16px;
  box-shadow: $box-shadow;
  padding: 6px 20px;

  &__detail {
    display: flex;
    justify-content: space-around;
    color: $text-base;
    margin-top: 58px;

    > div:first-child {
      margin-right: 32px;
    }

    > div:last-child {
      width: 50%;
      height: 100%;
    }

    &__image {
      display: flex;
      flex-direction: column;
      align-items: center;

      > img {
        width: 450px;
        height: 300px;
        margin-bottom: 32px;
        box-shadow: $box-shadow-smooth;
        border-radius: 8px;
        object-fit: cover;
      }
    }

    &__desc {
      display: grid;
      grid-template-columns: 0.5fr 2fr;

      > label {
        font-weight: 600;
      }
    }
    &__actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 52px;

      > div:first-child {
        margin-right: 20px;
      }
    }
  }
}
</style>
