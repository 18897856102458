<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="card-plain">
            <template slot="header">
              <div class="header">
                <div class="header__title">
                  <h4 class="card-title">Management Transaksi</h4>
                  <p class="card-category">Management Transaksi</p>
                </div>
              </div>
            </template>
            <div class="form-filter">
              <BaseInput width="50" v-model="search" placeholder="Cari Berdasarkan nomor tagihan"/>
              <Button type="info" text="Cari" @click="onSearch" />
            </div>
            <div class="table-responsive">
              <l-table
                class="table-hover"
                :columns="table.columns"
                :data="table.data"
                @click="(e) => handleClick(e)"
              >
              </l-table>
            </div>
          </card>
        </div>
        <Loader v-show="isLoading" colorClass="l-gray" />

        <!-- show modal -->
        <Modal
          :showModal="showModal"
          textHeader="Transaksi"
          :isConfirmation="true"
          top="2"
          @cancel="onActionModal('cancel')"
          @oke="onActionModal('oke')"
        >
          <template>
            <div class="transaction-modal">
              <div>
                <!-- <img :src="ImageTransaction" alt="" /> -->
              </div>
              <div class="transaction-modal__detail">
                <label>Email</label>
                <p>{{ transaction.email }}</p>
                <label>Nomor Tagihan</label>
                <p>{{ transaction.invoiceNumber }}</p>
                <label>Jenis</label>
                <p>{{ transaction.model }}</p>
                <label>Total</label>
                <p>{{ formatRupiah(transaction.total) }}</p>
                <label>Tanggal Transaksi Diubah</label>
                <p>{{ getTime(transaction.updatedAt) }}</p>
                <label>Tanggal Transaksi Dibuat</label>
                <p>{{ getTime(transaction.createdAt) }}</p>
                <label>Status</label>
                <Dropdown
                  :defaultValue="transaction.status"
                  :options="options"
                  @input="(value) => onInput(value)"
                />
                <Button 
                  type="info" 
                  :customPadding="false" 
                  text="Download Invoice" 
                  @click="handleDownloadInvoice"
                  :hidden="!checkIsTransactionHasProofPayment()"
                />
              </div>
            </div>
          </template>
        </Modal>
        <Modal
          :showModal="showModalConfirmation"
          :isConfirmation="true"
          :useHeader="false"
          top="10"
          @cancel="onActionModalConf('cancel')"
          @oke="onActionModalConf('oke')"
        >
          <template>
            <div class="modal-confirmation">
              <h3>Apakah anda yakin untuk update status ?</h3>
              <img :src="imageConfirmation" alt="">
            </div>
          </template>
        </Modal>
      </div>
      <Pagination
        :page="pagination.page"
        @prev="onClick('prev')"
        @next="onClick('next')"
      />
    </div>
  </div>
</template>

<script>
import LTable from "src/components/Table.vue";
import Card from "src/components/Cards/Card.vue";
import Button from "@/components/Button/Button.vue";
import ManageTransactionUseCase from "./app/usecase";
import { MapTransaction } from "./app/mappers";
import Loader from "@/components/Loader/Loader.vue";
import Modal from "@/components/Modal/Modal.vue";
import ImageTransaction from "@/assets/img/manageTransaction/transaction.svg";
import Dropdown from "@/components/Dropdown/Dropdown.vue";
import Pagination from "@/components/Pagination/Pagination.vue";
import imageConfirmation from '@/assets/img/common/confirmation.svg'
import BaseInput from "@/components/Inputs/BaseInput.vue";

import { 
  TimeMapper, 
  formatCurrancy, 
  convertTransactionStatus, 
} from "@/app/utils";

export default {
  components: {
    LTable,
    Card,
    Button,
    Loader,
    Modal,
    Dropdown,
    Pagination,
    BaseInput
  },
  data() {
    return {
      ImageTransaction,
      imageConfirmation,
      table: {
        columns: [
        { label: 'Email', value: 'email' },
        { label: 'No Invoice', value: 'invoiceNumber' },
        { label: 'Status', value: 'status' },
        { label: 'Jenis', value: 'model' },
        { label: 'Total', value: 'total' },
        { label: 'Dibuat', value: 'updatedAt' },
        ],
        data: null,
      },
      options: [
        { label: "Menunggu Verifikasi", value: "Waiting Verification" },
        { label: "Proses", value: "Processed" },
        { label: "Tolak", value: "Rejected" },
        { label: "Gagal", value: "Cancel" },
        { label: "Setuju", value: "Done" },
      ],
      pagination: {
        page: 1,
        perPage: 10,
        totalPage: 1,
      },
      isLoading: false,
      showModal: false,
      transaction: {},
      isChange: false,
      showModalConfirmation: false,
      id: null,
      status: '',
      search: ''
    };
  },
  methods: {
    convertTransactionStatus,
    notifyVue(verticalAlign, horizontalAlign, color, message) {
      this.$notifications.notify({
        message: message,
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color,
      });
    },
    async getTransactions() {
      this.isLoading = true;

      const response = await ManageTransactionUseCase.getAll(
        this.pagination.page,
        this.pagination.perPage,
        this.search
      );
      if (!response.error) {
        this.table = {
          ...this.table,
          data: response.data.map((item) => {
            const transaction = MapTransaction(item);
            return {
              id: transaction.id,
              email: transaction.email,
              invoiceNumber: transaction.invoiceNumber,
              status: this.convertTransactionStatus(transaction.status),
              model: transaction.model,
              total: this.formatRupiah(transaction.total),
              updatedAt: TimeMapper(transaction.updatedAt, false),
            };
          }),
        };
        this.pagination = response.pagination
      } else {
        this.$notifications.notify({
          message: 'Gagal memuat data',
          icon: "nc-icon nc-app",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'danger',
        })
      }

      this.isLoading = false;
    },
    async getDetail(id) {
      this.isLoading = true;
      this.id = id
      const response = await ManageTransactionUseCase.get(id);
      if (response && !response.error) {
        this.transaction = response.data;
      } else {
        this.$notifications.notify({
          message: 'Gagal memuat data',
          icon: "nc-icon nc-app",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'danger',
        })
      }

      this.isLoading = false;
    },
    async updateTransaction() {
      this.isLoading = true
      const payload = {
        status: this.status
      }
      const response = await ManageTransactionUseCase.update(this.id, payload)
      if(response && !response.error) {
        this.showModalConfirmation = false
        this.notifyVue('top', 'right', 'success', `Data status berhasil di perbarui`)
        this.getTransactions()
      } else {
        this.notifyVue('top', 'right', 'danger', `Data status gagal di perbarui`)
      }

      this.isLoading = false
    },
    handleClick(event) {
      this.getDetail(event.id);
      this.showModal = true;
    },
    onActionModal(type) {
      if (type === "cancel") {
        this.showModal = false;
      }

      if (type === "oke") {
        if(this.isChange) {
          this.showModal = false
          this.showModalConfirmation = true
        } else {
          this.showModal = false
        }
      }
    },
    getTime(time) {
      return TimeMapper(time, false);
    },
    formatRupiah(value) {
      if (value) {
        return formatCurrancy(value);
      }
    },
    onClick(type) {
      if (type === "prev" && this.pagination.page !== 1) {
        this.pagination.page--;
      }

      if (
        type === "next" &&
        this.pagination.page !== this.pagination.totalPage
      ) {
        this.pagination.page++;
      }

      this.getTransactions()
    },
    onInput(value) {
      this.isChange = true;
      this.status = value
    },
    onActionModalConf(type) {
      if(type === 'cancel') {
        this.showModalConfirmation = false
      }
      if(type === 'oke') {
        this.updateTransaction()
        this.showModalConfirmation = false
      }
      this.isChange = false
    },
    onSearch() {
      this.pagination.page = 1
      this.getTransactions()
    },
    checkIsTransactionHasProofPayment() {
      return (this.transaction.sppDetail && this.transaction.sppDetail.proof_payment_image) || 
        (this.transaction.programDetail && this.transaction.programDetail.proof_payment_image)
    },
    async handleDownloadInvoice() {
      if (!this.checkIsTransactionHasProofPayment()) {
        return;
      }
      
      const imageUrl = this.transaction.model === 'spp' 
        ? this.transaction.sppDetail.proof_payment_image
        : this.transaction.programDetail.proof_payment_image
      
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'invoice.jpg';
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  created() {
    this.getTransactions();
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/lbd/variables";
.header {
  display: flex;
  justify-content: space-between;
}

.transaction-modal {
  position: relative;
  height: auto;

  > div:first-child {
    display: flex;
    justify-content: center;
    opacity: 20%;
    margin: 12px 0;
  }


  &__detail {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;

    > label {
      color: $text-base;
      font-weight: 700;
    }

    > p {
      color: $black-color;
      font-weight: 800;
    }
  }
}

.modal-confirmation {
  text-align: center;

  > img {
    width: 50%;
    margin: 20px 0;
  }
}

.form-filter {
  display: flex;

  > div:first-child {
    margin-right: 20px;
  }
}
</style>
