<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <Card class="card-plain">
            <template slot="header">
              <div class="header">
                <div class="header__title">
                  <h4 class="card-title">Management Broadcast</h4>
                  <p class="card-category">Management content Broadcast</p>
                </div>
                <Button text="Tambah Broadcast" @click="() => {showModal = true}" />
              </div>
            </template>
            <div class="form-filter">
              <BaseInput
                width="50"
                v-model="search"
                placeholder="Cari Berdasarkan subject"
              />
              <Button type="info" text="Cari" @click="() => {getAllBroadcast()}" />
            </div>
            <div class="table-responsive">
              <l-table
                class="table-hover"
                :columns="table.columns"
                :data="table.data"
                @click="(e) => handleClickDetail(e)"
              >
              </l-table>
            </div>
          </Card>
        </div>
        <Modal
          :showModal="showModal"
          :useHeader="true"
          :isConfirmation="true"
          textHeader="Tambah Email Broadcast"
          @cancel="onActionsModal('cancel')"
          @oke="onActionsModal('oke')"
          textButton="Simpan"
          top="10"
          width="8"

        >
        <template>
          <div >
            <label style="color: #4d4d4d;" for="">Subject<span style="color: red;">*</span> :</label>
            <BaseInput
              width="50"
              v-model="form.subject"
              placeholder="Silahkan masukan email subject"
              
              style="width: 100%; margin-bottom: 0;"
            />
            <p style="color: red; font-size: 12px;" v-if="error.subject">{{ error.subject }}</p>
            
          </div>
          <div style="margin-top: 24px;">
            <label style="color: #4d4d4d;" for="">Description<span style="color: red;">*</span> :</label>
            <VueEditor v-model="form.description" placeholder="Silahkan masukan content email anda" />
            <p style="color: red; font-size: 12px;" v-if="error.description">{{ error.description }}</p>
          </div>
        </template>
      </Modal>
        <Loader v-show="isLoading" colorClass="l-gray" />
      </div>
      <Pagination
        :page="pagination.page"
        :totalPage="pagination.totalPage"
        @prev="onClick('prev')"
        @next="onClick('next')"
      />
    </div>
  </div>
</template>

<script>
import LTable from "src/components/Table.vue";
import Card from "src/components/Cards/Card.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import Pagination from "@/components/Pagination/Pagination.vue";
import Loader from "@/components/Loader/Loader.vue";
import ManageBroadcastUseCase from './app/usecase/index'

export default {
  components: {
    LTable,
    Card,
    Pagination,
    BaseInput,
    Loader,
  },
  data() {
    return {
      table: {
        columns: [
        { label: "Subjek", value: "subject", style: {minWidth: "250px"} },
        { label: "Status", value: "status", style: {minWidth: "250px"} },
        { label: "Dibuat", value: "createdAt", style: {minWidth: "250px"} },
        ],
        data: [],
      },
      pagination: {
        page: 1,
        perPage: 10,
        totalPage: 1,
      },
      form: {
        subject: null,
        description: null
      },
      error: {
        subject: null,
        description: null
      },
      search: "",
      isLoading: false,
      showModal: false
    };
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign, color, message) {
			this.$notifications.notify({
				message: message,
				icon: "nc-icon nc-app",
				horizontalAlign: horizontalAlign,
				verticalAlign: verticalAlign,
				type: color,
			});
		},
    onClick(type) {
      if (type === "prev" && this.pagination.page !== 1) {
        this.pagination.page--;
      }

      if (
        type === "next" &&
        this.pagination.page !== this.pagination.totalPage
      ) {
        this.pagination.page++;
      }
      this.getAllBroadcast()

    },

    async getAllBroadcast() {
      this.isLoading = true

      const { data, error, pagination } = await ManageBroadcastUseCase.getAll(this.pagination.page, this.pagination.perPage, this.search)

      if(error === null) {
        this.table.data = data.data
        this.pagination = pagination
        
      } else {
        this.notifyVue(
            'top',
            'right',
            'danger',
            'Gagal di buat'
          )
      }

      this.isLoading = false
    },
    async onActionsModal(type) {
      if(!this.form.subject) this.error.subject = "Subject wajib diisi!"
      if(this.form.subject) this.error.subject = ""
      if(!this.form.description) this.error.description = "description wajib diisi!"
      if(this.form.description) this.error.description = ""

      if(type === "oke" && this.form.subject && this.form.description) {
        this.isLoading = true
        const { data, error } = await ManageBroadcastUseCase.create(this.form)
        this.showModal = false
        if(data){
          this.notifyVue(
            'top',
            'right',
            'success',
            'Berhasil di buat'
          )
          this.$router.go()
        } else {
          this.notifyVue(
            'top',
            'right',
            'danger',
            'Gagal di buat'
          )
        }
      }
      else if(type === "cancel") {
        this.showModal = false
      }

      this.form = {
          subject: null,
          description: null
        }
      this.isLoading = false

    },
    handleClickDetail(value) {
      this.$router.push(`${this.$route.path}/detail/${value.subject.toLowerCase().split(' ').join(' ')}/${value.id}`);
    },
  },
  created() {
    this.getAllBroadcast()
    
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
}

.form-filter {
  display: flex;

  > div:first-child {
    margin-right: 20px;
  }
}
</style>
