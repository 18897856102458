<template>
  <div class="create-product">
    <Modal
      :showModal="showModal"
      :isConfirmation="true"
      :useHeader="false"
      width="28"
      @cancel="onModalActions(ENUM_ACTION.CANCEL)"
      @oke="onModalActions(ENUM_ACTION.OKE)"
    >
    <div class="text-confirmation">
      <h4>{{ textConfirmation }}</h4>
    </div>
    </Modal>
    <Loader colorClass="l-gray" v-if="isLoading" />
    <div class="create-product__form">
      <div class="create-product__form__title">
        <label>Nama Produk<span>*</span></label>
        <BaseInput v-model="form.name" placeholder="Silahkan masukan nama"/>
      </div>
      <div class="create-product__form__title">
        <label>Tipe Produk<span>*</span></label>
        <Dropdown
          placeholder="Select Type Product"
          :options="productType"
          @input="(value) => handleProductType(value)"
        />
      </div>
      <div class="create-product__form__title">
        <label>Harga <span>*</span></label>
        <BaseInput
          type="currency"
          @input="handlePrice"
          :value="form.price"
          placeholder="Silahkan masukan harga"
        />
      </div>
      <div class="create-product__form__discount">
        <div class="create-product__form__percentage">
          <label>Persentase Diskon (%)</label>
          <BaseInput
            type="number"
            @input="handlePercentage"
            :value="form.percentageDiscount"
            placeholder="%"
            width="80"
          />
          <span class="noted">Note: Tidak boleh melebihi 100%</span>
        </div>
        <div class="create-product__form__final-price">
          <label>Harga Final</label>
          <BaseInput
            type="currency"
            disabled
            v-model="form.finalPrice"
            placeholder="-"
          />
        </div>
      </div>
      <div class="create-product__form__description">
        <label>Deskripsi <span>*</span></label>
        <VueEditor
         v-model="form.description"
         placeholder="Silahkan masukan content product anda"
       />
      </div>
      <div class="create-product__form__title">
        <label>Gambar Produk <span>*</span></label>
        <MultipleImage @value="onMultipleUpload" @close="onChangeUpload" :maxImages="1" />
      </div>
      <div v-if="form.type === 'digital'" class="create-product__form__title">
        <label>Dokumen <span>*</span></label>
      <FileUpload @value="onUploadPdf"/>
      </div>
      <div class="create-product__form__actions">
        <Button type="danger" text="Kembali" @click="onActions(ENUM_ACTION.BACK)" />
        <Button :isDisabled="$v.form.$invalid" type="info" text="Simpan" @click="onActions(ENUM_ACTION.SUBMIT)" />
      </div>
    </div>
  </div>
</template>

<script>
import { ENUM_ACTION } from '@/app/Constants'
import { required, minLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { currencyDigit } from "@/app/utils";
import MultipleImage from '@/components/MultipleImage/MultipleImage.vue'
import ManageProductUseCase from '../app/usecase'
import { requiredIf } from 'vuelidate/lib/validators';
import { formatCurrencyToNumber } from '../../../app/utils';
import { VueEditor } from 'vue2-editor';

export default {
  components: {
    MultipleImage,
    VueEditor
  },
  mixins: [validationMixin],
  data() {
    return {
      ENUM_ACTION,
      showModal: false,
      isSubmit: false,
      textConfirmation: '',
      isLoading: false,
      productType: [
        {label: 'Digital', value: 'digital'},
        {label: 'Material', value: 'material'}
      ],
      message: {
        success: 'Product berhasil di buat',
        failed: 'Product gagal di buat'
      },
      form: {
        name: '',
        description: '',
        type: '',
        attachment: [],
        file: null,
        price: '',
        finalPrice: '',
        percentageDiscount: null
      }
    }
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign, color, typeMessage) {
      this.$notifications.notify({
        message: this.message[typeMessage],
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color,
      });
    },
    onModalActions(type) {
      if(type === ENUM_ACTION.CANCEL) {
        this.showModal = false
      }

      if(type === ENUM_ACTION.OKE) {
        this.createProduct(this.form)
      }
    },
    onChange() {},
    onActions(type) {
      if(type === ENUM_ACTION.BACK) {
        this.$router.push('/management-product')
      }

      if(type === ENUM_ACTION.SUBMIT) {
        this.textConfirmation = 'Apakah data sudah benar ?'
        this.showModal = true
      }

    },
    onMultipleUpload(value) {      
      this.form.attachment = value[1]
    },
    onUploadPdf(value) {
      this.form.file = value[1]
    },
    async createProduct(data) {
      this.isLoading = true
      const price = formatCurrencyToNumber(data.price)
      const finalPrice = formatCurrencyToNumber(data.finalPrice)
      const payload = new FormData()
      if(data.attachment?.length > 0) {
        data.attachment.forEach(item => {          
          payload.append('images[]', item)
        });
      }
      payload.append('name', data.name)
      payload.append('initiate_price', price)
      payload.append('final_price', finalPrice)
      payload.append('discount_percentage', data.percentageDiscount)
      payload.append('type', data.type)
      payload.append('description', data.description)
      if(data.type === 'digital') {
        payload.append('files[]', data.file)
      }

      const response = await ManageProductUseCase.create(payload)      
      if(response && !response.error) {
        this.notifyVue('top', 'right', 'success', 'success')
        this.$router.push('/management-product')
      } else {
        this.notifyVue('top', 'right', 'danger', 'failed')
      }

      this.showModal = false
      this.isLoading = false
    },
    handleProductType(value) {
      this.form.type = value 
    },
    handlePercentage(value) {
      if(Number(value) > 100 || Number(value) < 0) return
      
      // if(Number(value) > 100) return
      this.form.percentageDiscount = Number(value);
      const price =
        this.form.price ? this.form.price?.replaceAll(".", "") : "";

      this.form.finalPrice = currencyDigit(
        price - (Number(price) / 100) * value
      );
    },
    handlePrice(value) {
      this.form.price = value
      const price = !value ? '' : value?.replaceAll('.', '')

      this.form.finalPrice = currencyDigit(
        Number(price) - (Number(price) / 100) * this.form.percentageDiscount
      );
    },
    onChangeUpload(value) {      
      this.form.attachment = value[1]
    }
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        description: {
          required,
        },
        attachment: {
          required,
          minLength: minLength(1)
        },
        file: {
          required: requiredIf(() => this.form.type === 'digital')
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/sass/lbd/variables';

.create-product {
  width: 90%;
  background-color: white;
  margin: auto;
  padding: 20px;

  &__form {
    display: flex;
    flex-direction: column;

    > div:first-child {
      margin-bottom: 12px;
    }

    > div {
      margin-bottom: 32px;

      > label {
        color: $text-base;
        font-weight: 600;

        span {
          color: $danger-states-color;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;

      > div {
        margin-right: 12px;
      }
    }
  }
}

.text-confirmation {
  text-align: center;
  margin-bottom: 20px;
}

textarea {
  padding: 10px 20px;
  width: 100%;
  color: $text-base;
  border: 1px solid $light-gray;
  border-radius: 4px;

  &::placeholder {
    color: $light-gray;
  }
}

textarea:focus {
  outline: none;
  border: 1px solid $dark-gray;
}
.noted {
  font-size: 12px;
  color: #4449;
}
.create-product__form__discount {
  display: flex;
  align-items: start;

  .create-product__form__final-price {
    width: 100%;
  }
}
</style>
