<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="card-plain">
            <template slot="header">
              <div class="header">
                <div class="header__title">
                  <h4 class="card-title">Management Program</h4>
                  <p class="card-category">Management Program</p>
                </div>
                <Button text="Tambah Program" @click="navigateToCreate" />
              </div>
            </template>
            <div class="form-filter">
              <BaseInput
                width="50"
                v-model="search"
                placeholder="Cari Berdasarkan nama"
              />
              <Button type="info" text="Cari" @click="getPrograms" />
              <div class="" style="display: flex; margin-left: 20px; width: 30%; justify-content: end;">
                <h4 style="margin-right: 20px;">Is Favorite:</h4>
                <Checkbox :value="isFavorite" @click="(v) => onClickIsFavorite(v)" />
              </div>
            </div>
            <div class="table-responsive">
              <l-table
                class="table-hover"
                :isShowActions="false"
                :columns="table.columns"
                :data="table.data"
                @click="(e) => navigateToDetail(e)"
              >
                <!-- <template v-slot:actions="slotProps">
                  <td>
                    <button 
                      class="table-item-action table-item-action__detail" 
                      @click.stop="toggleModalDetailProgram(slotProps.id)"
                    >
                      <i class="fa fa-eye fa-md"></i>
                    </button>
                  </td>
                </template> -->
              </l-table>
            </div>
          </card>
        </div>
        <Loader v-show="isLoading" colorClass="l-gray" />
        
        <!-- open Modal Detail Program -->
        <Modal
          :showModal="showModalDetailProgram"
          :useHeader="true"
          :isConfirmation="false"
          textHeader="Detail Akun Program"
          @cancel="{}"
          @oke="toggleModalDetailProgram(null)"
          textButton="Oke"
          top="10"
        >
          <div class="container mt-5">
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Nomor Rekening</strong>
                <span class="badge badge-primary badge-pill">123456789</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Bank Tujuan</strong>
                <span class="badge badge-primary badge-pill">MANDIRI</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <strong>Nama Penerima</strong>
                <span class="badge badge-primary badge-pill">Muhammad Fadzrin Madu</span>
              </li>
            </ul>
          </div>
        </Modal>
        
        <!-- open Modal -->
        <Modal
          :showModal="showModal"
          :useHeader="true"
          :isConfirmation="true"
          textHeader="Tambah Program Form"
          @cancel="() => {}"
          @oke="() => {}"
          textButton="Simpan"
          top="10"
        >
          <template>
            <div class="modal-product">
              <label for="nama">Nama</label>
              <BaseInput
                placeholder="Masukan nama"
                type="text"
                v-model="form.name"
                :errorMessage="error.name"
                required
              />
              <label for="email">Email</label>
              <BaseInput
                placeholder="Masukan email"
                type="email"
                v-model="form.email"
              />
              <label for="phone">Nomor Handphone</label>
              <BaseInput
                addonLeftIcon="+62"
                placeholder="Masukan nomor handphone"
                type="number"
                :value="form.phone"
                @input="(value) => {}"
                required
              />
              <label for="address">Alamat</label>
              <BaseInput
                placeholder="Masukan alamat"
                type="text"
                v-model="form.address"
                required
              />
            </div>
          </template>
        </Modal>
      </div>
      <Pagination
        :page="pagination.page"
        @prev="paginationActions(ENUM_ACTION.PREV)"
        @next="paginationActions(ENUM_ACTION.NEXT)"
      />
    </div>
  </div>
</template>

<script>
import LTable from "src/components/Table.vue";
import Card from "src/components/Cards/Card.vue";
import ManageProgramRepository from "./app/usecase";
import { MapProgram } from "./app/mappers";
import { ENUM_ACTION } from "@/app/Constants";
import Checkbox from "@/components/Checkbox/Checkbox.vue";

export default {
  components: {
    LTable,
    Card,
    Checkbox
  },
  data() {
    return {
      ENUM_ACTION,
      table: {
        columns: [
          { label: "Nama", value: "name" },
          { label: "Urutan Favorite", value: "favoriteOrderNumber" },
          { label: "Biaya Pendaftaran", value: "initiatePrice" },
          { label: "Biaya Tahunan (Min. 50℅)", value: "yearlyPrice" },
          { label: "Dibuat", value: "createdAt" },
          { label: "Diubah", value: "updatedAt" },
        ],
      },
      pagination: {
        page: 1,
        perPage: 10,
        totalPage: 1,
      },
      isLoading: false,
      showModal: false,
      showModalDetailProgram: false,
      isFavorite: false,
      type: "text",
      form: {
        name: "",
        email: "",
        phone: "",
        address: "",
        password: "",
      },
      search: "",
      error: [],
      notifications: {
        topCenter: false,
      },
      errorMessage: {
        empty: "Data anda tidak boleh kosong",
        email: "Email anda tidak valid",
        name: "nama tidak boleh angka",
        password: "Password harus lebih dari 8 huruf",
        phone: "Nomor handphone setidak nya 9 angka sampai 15 angka",
        address: "Pastikan alamat diisi dengan benar",
      },
    };
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign, color, item) {
      this.$notifications.notify({
        message: this.errorMessage[item],
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color,
      });
    },
    async getPrograms() {
      this.isLoading = true;
      const response = await ManageProgramRepository.getAll(
        this.pagination.page,
        this.pagination.perPage,
        this.search,
        this.isFavorite
      );

      if (response && !response.error) {
        this.table = {
          ...this.table,
          data: response.data.data.map((item) => {
            return MapProgram(item);
          }),
        };
        
        this.pagination = {
          page: response.data.pagination.page,
          perPage: response.data.pagination.perPage,
          totalPage: response.data.pagination.totalPage
        }
      } else {
        this.notifyVue("top", "right", "danger", "failed");
      }
      this.isLoading = false;

    },
    navigateToDetail(event) {
      this.$router.push(
        `${this.$route.path}/detail/${event.name}?id=${event.id}`
      );
    },
    navigateToCreate() {
      this.$router.push(`${this.$route.path}/create`);
    },
    paginationActions(type) {
      if (
        type === ENUM_ACTION.NEXT &&
        this.pagination.page !== this.pagination.totalPage
      ) {
        this.pagination.page++;
      }

      if (
          type === ENUM_ACTION.PREV &&
          this.pagination.page > 1
        ) {
        this.pagination.page--;
      }

      this.getPrograms();
    },
    toggleModalDetailProgram(id) {
      const selectedData = this.table.data.filter((item) => item.id === id)[0];
      this.showModalDetailProgram = !this.showModalDetailProgram;
    },
    onClickIsFavorite(value) {
      this.isFavorite = value
      this.getPrograms()
    }
  },
  created() {
    this.getPrograms();
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
}

.form-filter {
  display: flex;
  justify-content: space-between;

  > div:first-child {
    margin-right: 20px;
  }
}

.table-item-action {
  display: inline-block;
  width: 36px;
  height: 36px;
  color: white;
  font-weight: bold;
  line-height: 36px;
  border-radius: 6px;
  box-sizing: border-box;
  border: none;
  
  &__detail {
    background-color: gray;
  }
}

</style>
