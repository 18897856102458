<template>
    <div class="management-broadcast">
        <div class="">
            <BackComponent />
        </div>
        <div class="management-broadcast__detail">
            <p style="font-weight: 700;" class="">Subject:</p>
            <p class="">{{ data?.subject || '-' }}</p>
            <p style="font-weight: 700;" class="">Description:</p>
            <div class="" v-html="data?.description || '-'"></div>
            
        </div>
        <Loader v-show="isLoading" colorClass="l-gray" />
    </div>
</template>


<script>
import Loader from "@/components/Loader/Loader.vue";
import ManageBroadcastUseCase from '../app/usecase/index'

export default {
    components: {
        Loader
    },
    data() {
        return {
            data: null,
            isLoading: false
        }
    },
    created() {
        this.getBroadcast()
    },
    methods: {
        notifyVue(verticalAlign, horizontalAlign, color, message) {
			this.$notifications.notify({
				message: message,
				icon: "nc-icon nc-app",
				horizontalAlign: horizontalAlign,
				verticalAlign: verticalAlign,
				type: color,
			});
		},
        async getBroadcast() {
            this.isLoading = true
            await ManageBroadcastUseCase.getDetail(this.$route.params.id)
                .then(resp => {
                    this.data = resp?.data
                })
                .catch((err) => {
                    this.notifyVue(
                        'top',
                        'right',
                        'danger',
                        'Gagal memuat data'
                    )
                    this.$router.back()
                })
                .finally(() => this.isLoading = false)
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../../assets/sass/lbd/variables";
.management-broadcast {
  width: 90%;
  background-color: white;
  border-radius: 8px;
  margin: auto;
  box-shadow: $box-shadow;
  padding: 0px 20px;
  padding-bottom: 32px;
  color: #4a4a4a;
  font-weight: 500;

  &__detail {
    padding-top: 78px;
    padding-left: 24px;
    padding-right: 24px;
    display: grid;
    grid-template-columns: 0.5fr 2fr;
  }
}
  </style>
