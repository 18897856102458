<template>
  <div class="management-article">
    <BackComponent />
    <div class="management-article__title">
      <h2>Detail</h2>
    </div>
    <Loader v-if="isLoading" colorClass="l-gray" />
    <div v-else class="management-article__profile">
      <!-- <div class="management-article__profile__foto">
        <img :src="ImageAdmin" alt="" />
      </div> -->
      <div class="management-article__profile__detail">
        <label>Title: </label>
        <p v-if="state === ENUM_ACTION.DETAIL">{{ data.title }}</p>
        <BaseInput
          v-else
          type="text"
          :value="data.title"
          @input="(e) => handleInput(e, 'title')"
        />

        <label>Slug: </label>
        <p v-if="state === ENUM_ACTION.DETAIL">{{ data.slug }}</p>
        <BaseInput
          v-else
          type="text"
          :value="data.title"
          @input="(e) => handleInput(e, 'title')"
        />

        <label>Category: </label>
        <p v-if="state === ENUM_ACTION.DETAIL">{{ data.category }}</p>
        <BaseInput
          v-else
          type="text"
          :value="data.title"
          @input="(e) => handleInput(e, 'title')"
        />

        <label>Attachment Article:</label>
        <div
          class="management-article__profile__detail__image"
        >
          <img
            v-if="attachmentArticle && state === ENUM_ACTION.DETAIL"
            :src="attachmentArticle"
            alt=""
          />
          <p v-else-if="!attachmentArticle && state === ENUM_ACTION.DETAIL">Gambar tidak tersedia</p>
        </div>
      </div>
    </div>

    <Modal
      :showModal="showModalDelete"
      :useHeader="false"
      :isConfirmation="true"
      width="32"
      @cancel="onActionModal(ENUM_ACTION.CANCEL)"
      @oke="onActionModal(ENUM_ACTION.OKE)"
    >
      <template>
        <div class="modal-article">
          <h4>{{ textConfirmation }}</h4>
        </div>
      </template>
    </Modal>

    <Modal
      :showModal="showModalUpdate"
      :useHeader="false"
      :isConfirmation="true"
      width="32"
      @cancel="onActionModalUpdate(ENUM_ACTION.CANCEL)"
      @oke="onActionModalUpdate(ENUM_ACTION.OKE)"
    >
      <template>
        <div class="modal-article">
          <h4>{{ textConfirmation }}</h4>
        </div>
      </template>
    </Modal>

    <div class="management-article__actions">
      <Button
        v-if="state === ENUM_ACTION.DETAIL"
        type="info"
        text="Edit"
        @click="onActions(ENUM_ACTION.EDIT)"
      />
      <Button
        v-if="state === ENUM_ACTION.DETAIL"
        type="danger"
        text="Hapus"
        @click="onActions(ENUM_ACTION.DELETE)"
      />
      <div v-else style="display: flex">
        <Button
          style="margin-right: 10px"
          type="info"
          text="Kembali"
          @click="onActions(ENUM_ACTION.BACK)"
        />
        <Button
          type="success"
          text="Simpan"
          @click="onActions(ENUM_ACTION.SUBMIT)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader/Loader.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ImageAdmin from "@/assets/img/manageAdmin/hijab_work.jpg";
import Button from "@/components/Button/Button.vue";
import { VueEditor } from "vue2-editor";
import { ENUM_ACTION } from "@/app/Constants";
import ImageUpload from "@/components/ImageUpload/ImageUpload";
import ManageArticleUseCase from "../app/usecase";
import { validationMixin } from 'vuelidate'
import Modal from "@/components/Modal/Modal.vue";

export default {
  components: {
    Loader,
    BaseInput,
    Button,
    VueEditor,
    ImageUpload,
    Modal
  },
  mixins: [validationMixin],
  data() {
    return {
      ENUM_ACTION,
      ImageAdmin,
      isLoading: false,
      state: "DETAIL",
      data: null,
      attachmentArticle: "",
      showModalDelete: false,
      showModalUpdate: false,
      isChange: false,
      textConfirmation: '',
      message: {
        success: "Berhasil menghapus data akun artikel",
        failedGet: 'Gagal mendapatkan data',
        failed: "Gagal Menghapus data",
        updated: "Berhasil mengubah data akun artikel",
        failedUpdate: "Gagal mengubah data akun artikel",
        empty: 'Data anda tidak boleh kosong',
        email: 'Email anda tidak valid',
        name: 'nama tidak boleh angka',
        password: 'Password harus lebih dari 8 huruf',
        phone: 'Nomor handphone setidak nya 9 angka sampai 15 angka',
        position: 'Pastikan alamat diisi dengan benar'
      },
      form: {
        title: '',
        attachmentArticle: '',
        article: ''
      },
      formUpdate: {
        title: false,
        attachmentArticle: false,
        article: false
      },
      payload: {}
    };
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign, color, typeMessage) {
      this.$notifications.notify({
        message: this.message[typeMessage],
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color,
      });
    },
    onActions(type) {
      if (type === ENUM_ACTION.EDIT) {
        this.state = ENUM_ACTION.EDIT;
        const path = `/management-article/edit/${this.data.title}?id=${this.data.id}`
        this.$router.push(path);
      }

      if (type === ENUM_ACTION.BACK) {
        if (this.isChange) {
          this.showModalUpdate = true;
          this.textConfirmation =
            "Apakah anda yakin ingin menghapus perubahan data tersebut ?";
          } else {
            this.state = ENUM_ACTION.DETAIL;
          }
          this.payload = {}
      }

      if(type === ENUM_ACTION.SUBMIT) {
        if(!this.isChange) {
          return;
        } else {
          this.textConfirmation =
            "Apakah anda yakin ingin melakukan perubahan data tersebut ?";
          this.showModalUpdate = true
        }
        for(let key in this.formUpdate) {
          if(this.formUpdate[key]) {
            if(key === 'attachmentArticle') {
                this.payload[key] = this.form[key][1]
              } else {
                this.payload[key] = this.form[key]
              }
          }
        }
      }

      if(type === ENUM_ACTION.DELETE) {
        this.textConfirmation = 'Apakah anda yakin ingin menghapus data tersebut ?'
        this.showModalDelete = true
      }
    },
    onActionModal(type) {
      if(type === ENUM_ACTION.CANCEL) {
        this.showModalDelete = false
      }

      if(type === ENUM_ACTION.OKE) {
        this.deleteArticle()
      }
    },
    onActionModalUpdate(type) {
      if(type === ENUM_ACTION.CANCEL) {
        this.showModalUpdate = false
      }

      if(type === ENUM_ACTION.OKE) {
        if(Boolean(Object.keys(this.payload).length)) {
          this.updateArticle(this.payload, this.$route.query.id)
        } else {
          this.state = ENUM_ACTION.DETAIL
        }
        this.showModalUpdate = false
        this.isChange = false
      }
    },
    async getArticle() {
      this.isLoading = true;
      const response = await ManageArticleUseCase.get(this.$route.query.id);
      if (response && !response.error) {
        this.data = response.data;
        this.attachmentArticle = Boolean(response?.data?.attachment?.length)
          ? response?.data?.attachment[0].url
          : "";
          this.form.article = response?.data?.description
      } else {
        this.notifyVue('top', 'right', 'danger', 'failed')
      }

      this.isLoading = false;
    },
    async updateArticle(payload, id) {
      this.isLoading = true
      let dataUpdate = new FormData()
      if(Boolean(payload?.title)) {
        dataUpdate.append('title', payload.title)
      }
      if(Boolean(payload?.article)) {
        dataUpdate.append('description', payload.article)
      }
      if(payload?.attachmentArticle) {
        dataUpdate.append(`image_${this.data.attachment[0].id}`, payload.attachmentArticle)
      }

      const response = await ManageArticleUseCase.update(id, dataUpdate)
      if(response && !response.error) {
        this.notifyVue('top', 'right', 'success', 'updated')
      } else {
        this.notifyVue('top', 'right', 'danger', 'failedUpdate')
      }

      this.state = ENUM_ACTION.DETAIL
      this.isLoading = false
    },
    async deleteArticle() {
      this.isLoading = true
      const response = await ManageArticleUseCase.delete(this.$route.query.id)
      if(response && !response.error) {
        this.$router.push('/management-article')
        this.notifyVue('top', 'right', 'success', 'success')
      } else {
        this.notifyVue('top', 'right', 'danger', 'failedGet')
      }
    },
    handleInput(value, type) {
      this.form[type] = value
      this.isChange = true
      if(value === this.form[type]) {
        this.formUpdate[type] = true
      } else {
        this.formUpdate[type] = false
      }
    },
    onChangeEditor() {
      this.isChange = true
      this.formUpdate.article = true
    },
    resetState() {
      this.form = {
        title: '',
        slug: '',
        attachmentArticle: '',
        article: ''
      }
    },
    onChangeUpload(value) {
      this.form.attachmentArticle = value
      this.isChange = true
    }
  },
  created() {
    this.getArticle();
  },
  watch: {
    state() {
      this.resetState()
      this.getArticle()
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/sass/lbd/variables";

.management-article {
  width: 80%;
  background-color: white;
  border-radius: 8px;
  margin: auto;
  box-shadow: $box-shadow;
  padding: 0px 20px;
  padding-bottom: 32px;
  color: $dark-gray;
  font-weight: 500;

  &__title {
    margin: 0;
    text-align: center;
    padding: 20px;
    border-bottom: 1px solid $light-gray;
    margin-bottom: 32px;

    /deep/ h2 {
      color: $black-hr;
      font-weight: 600;
    }
  }

  &__profile {
    display: flex;

    &__foto {
      width: 30%;

      > img {
        width: 380px;
        object-fit: cover;
        opacity: 50%;
      }
    }

    &__detail {
      z-index: 1;
      display: grid;
      grid-template-columns: auto auto;
      max-width: 100%;
      margin-bottom: 18px;

      label {
        color: $text-base;
        font-weight: 600;
        margin-right: 20px;
      }

      &__image {
        margin-bottom: 32px;

        > img {
          width: 400px;
          height: 200px;
          object-fit: cover;
          border-radius: 16px;
          box-shadow: $box-shadow;
        }
      }
    }
  }

  &__actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    > div {
      margin-right: 16px;
    }
  }
}

.modal-article {
  margin-bottom: 32px;

  /deep/ h4 {
    text-align: center;
    color: $text-base;
  }
}


</style>
