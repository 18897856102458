<template>
  <div class="wrapper">
    <side-bar title="">
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link to="/dashboard">
        <i class="nc-icon nc-chart-pie-35"></i>
        <p>Dashboard</p>
      </sidebar-link>
      <sidebar-link to="/management-user">
        <i class="nc-icon nc-badge"></i>
        <p>Management Akun User</p>
      </sidebar-link>
      <sidebar-link to="/management-siswa">
        <i class="nc-icon nc-badge"></i>
        <p>Management Siswa</p>
      </sidebar-link>
      <sidebar-link to="/management-wali">
        <i class="nc-icon nc-badge"></i>
        <p>Management Ortu/Wali</p>
      </sidebar-link>
      <sidebar-link to="/management-admin">
        <i class="nc-icon nc-single-02"></i>
        <p>Management Akun Admin</p>
      </sidebar-link>
      <sidebar-link to="/management-event">
        <i class="nc-icon nc-notes"></i>
        <p>Management Event</p>
      </sidebar-link>
      <sidebar-link to="/management-article">
        <i class="nc-icon nc-single-copy-04"></i>
        <p>Management Artikel</p>
      </sidebar-link>
      <sidebar-link to="/management-product">
        <i class="nc-icon nc-cart-simple"></i>
        <p>Management Produk</p>
      </sidebar-link>
      <sidebar-link to="/management-transactions">
        <i class="nc-icon nc-money-coins"></i>
        <p>Management Transaksi</p>
      </sidebar-link>
      <sidebar-link to="/management-broadcast">
        <i class="nc-icon nc-notification-70"></i>
        <p>Management Broadcast</p>
      </sidebar-link>
      <sidebar-link to="/management-program">
        <i class="nc-icon nc-settings-90"></i>
        <p>Management Program</p>
      </sidebar-link>
      <sidebar-link to="/spp-pembayaran">
        <i class="nc-icon nc-credit-card"></i>
        <p>SPP Pembayaran</p>
      </sidebar-link>
      <sidebar-link to="/configuration">
        <i class="nc-icon nc-settings-gear-64"></i>
        <p>Konfigurasi</p>
      </sidebar-link>
    </side-bar>

      <!-- Dont Remove because for documentation -->

      <!-- <sidebar-link to="/typography">
        <i class="nc-icon nc-paper-2"></i>
        <p>Typography</p>
      </sidebar-link>
      <sidebar-link to="/icons">
        <i class="nc-icon nc-atom"></i>
        <p>Icons</p>
      </sidebar-link>
      <sidebar-link to="/notifications">
        <i class="nc-icon nc-bell-55"></i>
        <p>Notifications</p>
      </sidebar-link> -->
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click="toggleSidebar">

      </dashboard-content>
    </div>
  </div>
</template>
<style lang="scss">

</style>
<script>
  import TopNavbar from './TopNavbar.vue'
  import DashboardContent from './Content.vue'
  import MobileMenu from './MobileMenu.vue'

  export default {
    components: {
      TopNavbar,
      DashboardContent,
      MobileMenu
    },
    methods: {
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      }
    },
  }

</script>
