<template>
  <div id="mainButton" class="active">
    <div class="modal">
      <div class="form-title">Sign In</div>
      <Loader colorClass="l-gray" v-if="isLoading" />
      <div class="input-group">
        <input
          name="cc"
          type="email"
          autocomplete="off"
          v-model="username"
          id="name"
          @blur="(e) => checkInput(e)"
          required
        />
        <label for="name">Email</label>
      </div>
      <div class="input-group">
        <input
          name="password"
          type="password"
          id="password"
          autocomplete="new-password"
          v-model="password"
          @blur="(e) => checkInput(e)"
          @keyup.enter="login()"
          required
        />
        <label for="password">Password</label>
      </div>
      <div class="form-button" @click="login()">Login</div>
    </div>
  </div>
</template>

<script>
import Authentication from './app/usecase'
import { setCredentials } from '@/app/misc/Cookies.js'
import { validationWithRegex } from "@/app/utils";
import { ERROR_CODE } from './app/constants'
import Loader from '@/components/Loader/Loader.vue'

export default {
  components: { Loader },
  data() {
    return {
      ERROR_CODE,
      username: "",
      password: "",
      isLoading: false,
      message: {
        emailEmpty: 'Email tidak boleh kosong',
        passwordEmpty: 'Password tidak boleh kosong',
        empty: 'Email dan Password tidak boleh kosong',
        email: 'Pastikan format email benar',
      }
    };
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign, color, typeMessage) {
      this.$notifications.notify({
        message: this.message[typeMessage],
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color,
      });
    },
    checkInput(e) {
      if (Boolean(e.target.value)) {
        e.target.classList.add("active");
      } else {
        e.target.classList.remove("active");
      }
    },
    async login() {
      if(this.validation()) {
        this.isLoading = true
        const response = await Authentication.login({
          username: this.username,
          password: this.password
        })
        if(response && response.data) {
          setCredentials({ token: response.data.accessToken })
          window.location.href = window.location.origin + '/dashboard'
          localStorage.setItem('name', response.data?.fullName )
        } else {
          this.message = {
            ...this.message,
            response: this.ERROR_CODE[String(response?.error?.error_code)] || 'Email atau Password tidak ditemukan'
          }
          this.notifyVue('top', 'center', 'danger', 'response')
        }
        this.isLoading = false
      }
    },
    validation() {
      if(!this.username && !this.password) {
        this.notifyVue('top', 'center', 'danger', 'empty')
        return false
      } else if (!this.username) {
        this.notifyVue('top', 'center', 'danger', 'emailEmpty')
        return false
      } else if (!this.password) {
        this.notifyVue('top', 'center', 'danger', 'passwordEmpty')
        return false
      } else if (validationWithRegex(this.username, 'EMAIL')) {
        this.notifyVue('top', 'center', 'danger', 'email')
        return false
      } else {
        return true
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
